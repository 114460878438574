import React from "react";
import Spacer from "../components/spacer";
import Layout from "../components/layout";
import {
  P,
  TextLink,
  TextBlockHeading,
  PageHeading
} from "../components/typography";
import SEO from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";

const Testimonials = () => {
  return (
    <Layout>
      <SEO
        title="Treatment Services We Offer - See How We Can Help You"
        description="Multnomah Medical Clinic provides a number of soft tissue-related treatments including joint pain, carpal tunnel, plantar fasciitis, and more."
      />
      <div className="flex justify-center">
        <div className="text-center">
          {/* Section 1 */}
          <div className="px-6 xs:px-12 sm:px-20 md:px-32 lg:max-w-4xl">
            <PageHeading>Neuropathy & Joint Pain Treatments</PageHeading>
            <div className="my-4"></div>
            <StaticImage
              width={400}
              height={300}
              src="../images/neuropathy-image.jpg"
              className="rounded-lg"
            />
            <div className="px-2 mt-4">
              <P>
                We leverage our clinical expertise with acoustic wave therapy to
                treat a number of soft tissue-related conditions such as:&nbsp;
                <TextLink className="active" to="#carpal-tunnel/">
                  carpal tunnel
                </TextLink>
                ,&nbsp;
                <TextLink className="active" to="#plantar-fasciitis/">
                  plantar fasciitis
                </TextLink>
                ,&nbsp;
                <TextLink className="active" to="#patellar-tendonitis/">
                  patellar tendonitis
                </TextLink>
                ,&nbsp;
                <TextLink className="active" to="#neuropathy/">
                  neuropathy
                </TextLink>
                , and more.
              </P>

              <P margin="mb-6 mt-6">
                Wave therapy provides relief to the target region by delivering
                painless bursts of acoustic waves to the tissue. These waves
                lead to a number of localized healing effects such as{" "}
                <strong>increased blood flow</strong>,
                <strong>release of growth factors</strong>, and
                <strong>reduction of inflammation</strong>.
              </P>
              <P>
                Wave therapy has also been proven to promote axon regeneration,
                which is hugely beneficial for nerve-related injuries such as
                those caused by diabetes.
              </P>
            </div>
            <div className="w-full flex items-center justify-center">
              <iframe
                className="embed-vid-player md:w-1/2 sm:w-full mt-8"
                src="https://iframe.mediadelivery.net/embed/390189/863ac146-773d-47ca-8991-de483997d7e1"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div className="px-6 xs:px-12 sm:px-20 md:px-32 lg:max-w-4xl pt-12">
            <TextBlockHeading wide>Why wave therapy?</TextBlockHeading>
            <Spacer />
            <div className="px-2">
              <>
                <P margin="mb-6">
                  The biggest benefit of wave therapy is that it is completely
                  non-invasive.
                </P>
                <P margin="mb-6">
                  This means that you can start feeling better again without
                  having to resort to harmful pills, or painful injections and
                  surgeries.
                </P>
                <P>
                  For effectiveness, the results are clear.{" "}
                  <TextLink className="active" to="/science/">
                    Numerous studies
                  </TextLink>{" "}
                  show that patients can expect symptom relief that is{" "}
                  <i>at least</i> as good as invasive treatments but without the
                  downsides – and in many cases, the outcome is even better.
                </P>
              </>
            </div>
          </div>

          <div className="px-6 xs:px-12 sm:px-20 md:px-32 lg:max-w-4xl pt-12">
            <TextBlockHeading wide>
              Can acoustic wave therapy treat my condition?
            </TextBlockHeading>
            <Spacer />
            <div className="px-2">
              <>
                <P margin="mb-6">
                  Wave therapy can be applied to many major soft tissue
                  conditions. We constantly expand our offerings based on the
                  latest peer-reviewed research.
                </P>
                <P margin="mb-6">
                  Broadly, we treat the following: neuropathy, plantar
                  fasciitis, patellar tendonitis, carpal tunnel, calf or thigh
                  strain, shin splints, trochanteric hip pain, shoulder pain,
                  lateral and medial epicondylitis, and pseudoartrosis.
                </P>
                <P>
                  Even if your condition isn't listed, we treat each patient as
                  an individual.{" "}
                  <TextLink className="active" to="/contact-us/">
                    Call or contact us
                  </TextLink>{" "}
                  to schedule a consultation where we can conduct a medical
                  screening to determine whether our treatment would benefit
                  you.
                </P>
              </>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Testimonials;
